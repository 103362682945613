import { loadStripe } from "@stripe/stripe-js";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export var offerID = "test_offer";
export const useTestPayments = false;

if (process.env.NODE_ENV === "development" && useTestPayments) {
	offerID = "TEST_VIP_BASIC_monthly_annual";
	// offerID = "test_offer";
	// offerID = "BASIC_VIP_monthly";
} else {
	offerID = "VIP_monthly_annual";
}

var stripePublicKey;

if (process.env.NODE_ENV === "development" && useTestPayments) {
	stripePublicKey =
		"pk_test_51LKeXJLepduMeTmVnrKBRp8EHD6Gx4LAMsf4tIDiXVAPPVpnCdLORHIT85PzVy06U3uGQHokA9eOyECMaZ3XUM5A00bFQnnBwq";
} else {
	stripePublicKey =
		"pk_live_51LKeXJLepduMeTmVGnWeAiFFPr0LdmJoL9UvtuNGoaKSPhqdvWJu3DRM31a9FrX1buZkKC29UCElQrBoLIADmT4x00XUFSPN0u";
}

export const stripePromise = loadStripe(stripePublicKey);
