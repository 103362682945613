import React, { useState } from "react";
import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { redirectToCheckoutStart } from "../../redux/subscription/subscription.actions";
import { BlackTextButton } from "../black-text-button/black-text-button.styles";
import RoundedButton from "../rounded-button/rounded-button.component";
import TandCandPP from "../t&c-and-pp/t&c-and-pp.component";

import {
	ButtonContainer,
	MovingWhiteContainer,
	TextButtonContainer,
} from "./floating-offer.styles";
import {
	selectPackages,
	selectPackagesFromParentApp,
} from "../../redux/subscription/subscription.selectors";

import { selectParentAppOS } from "../../redux/metadata/metadata.selector";
import LoadingAnimationComponent from "../loading-animation/loading-animation.component";
import Price from "../price/price.component";
import { PriceContainer } from "../price/price.styles";

const FloatingOffer = ({
	redirectToCheckout,
	hasAlreadySubscribed,
	// packageFromParentApp,
	billingCycle,
}) => {
	const packages = useSelector(selectPackages);
	const packagesFromParentApp = useSelector(selectPackagesFromParentApp);
	const parentAppOS = useSelector(selectParentAppOS);
	const [VIPButtonID, setVIPButtonID] = useState("");
	const [BASICButtonID, setBASICButtonID] = useState("");
	const [VIPPackage, setVIPPackage] = useState({});

	useEffect(() => {
		const clientScrolled = () => {
			var VIP_subscribe_button_rect;
			try {
				VIP_subscribe_button_rect = document
					.getElementById(VIPButtonID)
					.getBoundingClientRect();

				const try_out_VIP_button = document
					.getElementById("try_out_vip_button")
					.getBoundingClientRect();

				if (
					VIP_subscribe_button_rect.top <
					try_out_VIP_button.top + 90
				) {
					//setIsVisible(false);
					document.getElementById(
						"MovingWhiteContainer"
					).style.opacity = "0";
				} else {
					document.getElementById(
						"MovingWhiteContainer"
					).style.opacity = "1";
				}
			} catch (error) {
				console.error(error);
			}

			if (
				hasAlreadySubscribed &&
				VIP_subscribe_button_rect.top < window.innerHeight
			) {
				document.getElementById("MovingWhiteContainer").style.opacity =
					"0";
			}
		};
		window.addEventListener("scroll", clientScrolled);
		return () => {
			window.removeEventListener("scroll", clientScrolled);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [VIPButtonID]);

	useEffect(() => {
		packages.forEach(pckg => {
			if (pckg.Main && pckg.BillingCycle === billingCycle) {
				if (VIPButtonID !== pckg.identifier + "_subscribe_button") {
					setVIPButtonID(pckg.identifier + "_subscribe_button");
					const parentPackage = packagesFromParentApp
						? packagesFromParentApp[pckg.identifier]
						: {};

					setVIPPackage({ ...pckg, ...parentPackage });
				}
			}
			if (pckg.SecondMain && pckg.BillingCycle === billingCycle) {
				if (BASICButtonID !== pckg.identifier + "_subscribe_button")
					setBASICButtonID(pckg.identifier + "_subscribe_button");
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [packages, billingCycle, packagesFromParentApp]);
	function ConversionButtonClicked() {
		if (VIPPackage) {
			console.log(VIPPackage);
			// console.log(packagesFromParentApp[]);
			redirectToCheckout({
				packageID: VIPPackage.identifier,
				packageFromParentApp: VIPPackage, //this variable(VIPPackage) should contain package from parent app and package from cloud functions
				// packageFromParentApp: packagesFromParentApp["VIP_monthly"],
				pckg: VIPPackage,
			});
		}
	}

	return (
		<div style={{ width: "0px", height: "0px" }}>
			{VIPPackage ? (
				<MovingWhiteContainer
					id={"MovingWhiteContainer"}
					{...{ $hasAlreadySubscribed: hasAlreadySubscribed }}
				>
					{!hasAlreadySubscribed ? (
						<LoadingAnimationComponent>
							{parentAppOS === "android" ? (
								<PriceContainer
									style={{
										marginBottom: "10px",
									}}
								>
									auto renews, cancel anytime
								</PriceContainer>
							) : null}

							<ButtonContainer>
								<RoundedButton
									id={"try_out_vip_button"}
									onClick={ConversionButtonClicked}
								>
									Try out VIP
								</RoundedButton>
								{BASICButtonID ? (
									<TextButtonContainer>
										<BlackTextButton
											onClick={() => {
												window.scrollTo(
													0,
													document
														.getElementById(
															BASICButtonID
														)
														.getBoundingClientRect()
														.top - 400
												);
											}}
										>
											{" "}
											view BASIC{" "}
										</BlackTextButton>
									</TextButtonContainer>
								) : null}
							</ButtonContainer>
							<Price
								style={{
									marginTop: "10px",
								}}
								{...{
									hasAlreadySubscribed,
									pckg: VIPPackage,
									packageFromParentApp: VIPPackage,
								}}
							></Price>
							{parentAppOS === "android" ? (
								<TandCandPP Text={"By subscribing"} />
							) : null}
						</LoadingAnimationComponent>
					) : (
						"↓"
					)}
				</MovingWhiteContainer>
			) : null}
		</div>
	);
};

const mapDispatchToProps = dispatch => ({
	redirectToCheckout: payload => {
		dispatch(redirectToCheckoutStart(payload));
	},
});

export default connect(null, mapDispatchToProps)(FloatingOffer);
